import React, { useState } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ScatterChart, Scatter } from 'recharts';

// 各種データ（実際の使用時はAPIから取得）
const salesData = [
  { name: '1月', 売上: 4000, 予測: 2400, 予算: 3000 },
  { name: '2月', 売上: 3000, 予測: 1398, 予算: 3000 },
  { name: '3月', 売上: 2000, 予測: 9800, 予算: 3000 },
  { name: '4月', 売上: 2780, 予測: 3908, 予算: 3000 },
  { name: '5月', 売上: 1890, 予測: 4800, 予算: 3000 },
  { name: '6月', 売上: 2390, 予測: 3800, 予算: 3000 },
];

const surveyData = [
  { name: '居心地', 評価: 4 },
  { name: '接客', 評価: 4.5 },
  { name: '盛り付け', 評価: 3.5 },
  { name: '味', 評価: 4.7 },
  { name: '提供時間', 評価: 3.7 },
];

const eventData = [
  { 入店率: 68, イベント規模: 3 },
  { 入店率: 72, イベント規模: 4 },
  { 入店率: 58, イベント規模: 2 },
  { 入店率: 80, イベント規模: 5 },
  { 入店率: 65, イベント規模: 3 },
];

const customerComments = [
  { id: 1, comment: "とても美味しかったです。また来ます！", rating: 5 },
  { id: 2, comment: "接客が素晴らしかったです。", rating: 4 },
  { id: 3, comment: "待ち時間が少し長かったです。", rating: 3 },
];

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(0);

  const renderTabContent = () => {
    switch(activeTab) {
      case 0:
        return (
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-2">売上概要</h3>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={salesData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="売上" stroke="#8884d8" />
                    <Line type="monotone" dataKey="予測" stroke="#82ca9d" strokeDasharray="5 5" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-2">お客様アンケート分析</h3>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={surveyData} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[0, 5]} />
                    <YAxis dataKey="name" type="category" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="評価" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="p-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-2">予算進捗と累積売上</h3>
              <div className="h-96">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={salesData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="売上" stroke="#8884d8" />
                    <Line type="monotone" dataKey="予算" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="p-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-2">日報確認欄</h3>
              <p>ここに日報の詳細を表示します。実際の実装では、日付選択機能や日報の詳細な内容を表示するコンポーネントを追加します。</p>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-2">イベントと入店率の相関</h3>
              <div className="h-96">
                <ResponsiveContainer width="100%" height="100%">
                  <ScatterChart>
                    <CartesianGrid />
                    <XAxis type="number" dataKey="イベント規模" name="イベント規模" />
                    <YAxis type="number" dataKey="入店率" name="入店率" />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter name="イベントと入店率" data={eventData} fill="#8884d8" />
                  </ScatterChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="p-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-2">お客様からのアンケートコメント</h3>
              {customerComments.map(comment => (
                <div key={comment.id} className="mb-4 p-4 bg-gray-100 rounded">
                  <p className="text-gray-600">{comment.comment}</p>
                  <div className="mt-2 text-yellow-500">
                    {"★".repeat(comment.rating)}{"☆".repeat(5 - comment.rating)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="bg-blue-600 text-white p-4">
        <h1 className="text-xl font-bold">飲食店分析ダッシュボード</h1>
      </header>

      <div className="bg-white border-b">
        <div className="flex">
          {['概要', '予算・売上', '日報', 'イベント分析', '顧客の声'].map((tab, index) => (
            <button
              key={index}
              className={`px-4 py-2 font-semibold ${activeTab === index ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600'}`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="flex-grow overflow-y-auto">
        {renderTabContent()}
      </div>

      <nav className="bg-white border-t border-gray-200 p-4">
        <div className="flex justify-around">
          <button className="text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
          </button>
          <button className="text-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
            </svg>
          </button>
          <button className="text-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Dashboard;
